import { SwapHorziontal } from '@dfds-ui/icons/system'
import { Headline } from '@dfds-ui/react-components'
import { media, theme } from '@dfds-ui/theme'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { isContentfulType } from '../../utils'
import { CardTextBlock, FlexCard } from '../Card'
import { AssetLink, EntryLink } from '../EntryLink'
import { FlexBox } from '../FlexBox'

const ScheduleContainer = styled(FlexBox)`
  flex: 1;
  flex-direction: column;

  ${media.greaterThan('m')`
    flex-direction: row;
  `}
`

const ScheduleColumn = styled(FlexBox)`
  margin: 20px 0;
  text-align: center;
  border-right: 0;

  ${media.greaterThan('m')`
     border-right: 1px solid black;
  `}

  &:last-child {
    border-right: 0;
  }
`

const ScheduleColumnRow = styled.div`
  margin-bottom: 20px;
  display: ${({ spaceEvenly }) => spaceEvenly && 'flex'};
  justify-content: ${({ spaceEvenly }) => spaceEvenly && 'space-between'};
  padding: ${({ spaceEvenly }) => spaceEvenly && '0 20px'};

  &:last-child {
    margin-bottom: 0;
  }
`

const TopWrapperFlexBox = styled(FlexBox)`
  flex-direction: column;
  align-items: flex-start;

  ${media.greaterThan('m')`
    flex-direction: row;
  `}
`

const HeadlineWrapperFlexBox = styled(FlexBox)`
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin: 0 0 10px 0;

  ${media.greaterThan('m')`
    justify-content: flex-start;
    margin: 0;
  `}
`

const StyledAssetLink = styled(AssetLink)`
  margin: 0;

  ${media.greaterThan('m')`
    margin-left: auto;
  `}
`

const FreightScheduleTableDaily = (schedule) => {
  const {
    departuresCollection: { items: departures },
    arrivalsCollection: { items: arrivals },
  } = schedule
  const { t } = useTranslation()
  return (
    <ScheduleContainer>
      <ScheduleColumn flex={1} directionColumn>
        <Headline as={'h3'}>{t('DEPARTURES')}</Headline>
        {departures.map(({ weekday, time }, index) => (
          <ScheduleColumnRow key={index} spaceEvenly>
            <span>{weekday}</span> {time}
          </ScheduleColumnRow>
        ))}
      </ScheduleColumn>
      <ScheduleColumn flex={1} directionColumn>
        <Headline as={'h3'}>{t('ARRIVALS')}</Headline>
        {arrivals.map(({ weekday, time }, index) => (
          <ScheduleColumnRow key={index} spaceEvenly>
            <span>{weekday}</span> {time}
          </ScheduleColumnRow>
        ))}
      </ScheduleColumn>
    </ScheduleContainer>
  )
}

const FreightScheduleFrequent = (schedule) => {
  const {
    freightScheduleListCollection: { items: days },
  } = schedule

  return (
    <>
      {days.map((day, index) => (
        <ScheduleColumn key={`days${index}`} flex={1} directionColumn>
          <Headline as={'h3'}>{day.weekday}</Headline>
          {day.departureTimes.map((time, index) => (
            <ScheduleColumnRow key={`${day}time${index}`}>
              {time}
            </ScheduleColumnRow>
          ))}
        </ScheduleColumn>
      ))}
    </>
  )
}

const FreightScheduleSeasonal = (schedule) => {
  const {
    seasonalScheduleCollection: { items: seasonal },
  } = schedule
  const { t } = useTranslation()

  return (
    <>
      <FlexBox flex={1} directionColumn>
        {seasonal.map((season, index) => (
          <div
            key={`seasoncontainer${index}`}
            css={css`
              margin: 20px 0;
            `}
          >
            <div>
              {t('GENERAL-FROM')} {season.fromDate} {t('GENERAL-TO')}{' '}
              {season.toDate}
            </div>
            <ScheduleContainer key={`season${index}`}>
              <ScheduleColumn directionColumn flex={1}>
                <Headline as={'h3'}>{t('DEPARTURES')}</Headline>
                {season.departures.map((departure, index) => (
                  <ScheduleColumnRow key={`${season}${departure}${index}`}>
                    {departure}
                  </ScheduleColumnRow>
                ))}
              </ScheduleColumn>
              <ScheduleColumn directionColumn flex={1}>
                <Headline as={'h3'}>{t('ARRIVALS')}</Headline>
                {season.arrivals.map((arrival, index) => (
                  <ScheduleColumnRow key={`${season}${arrival}${index}`}>
                    {arrival}
                  </ScheduleColumnRow>
                ))}
              </ScheduleColumn>
            </ScheduleContainer>
          </div>
        ))}
      </FlexBox>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;

  ${media.greaterThan('l')`
    flex-direction: row;
  `}

  > div {
    width: 100%;
    margin-bottom: 40px;

    ${media.greaterThan('l')`
      &[md="8"], &[lg="8"] {
        width: calc(66.66% - 20px);
      }
      &[md="6"], &[lg="6"] {
        width: calc(50% - 20px);
      }
      &[md="4"], &[lg="4"] {
        width: calc(33.33% - 26.66px);
      }
      &[md="3"], &[lg="3"] {
        width: calc(25% - 30px);
      }
    `}
  }
`

const FreightShippingSchedule = ({
  scheduleForOneWay,
  scheduleForOtherWay,
  schedulePdfForDownload,
}) => {
  const [toggle, setToggle] = React.useState(true)
  const { t } = useTranslation()
  const {
    routeName,
    sailingTime,
    scheduleTable,
    rightTextSection,
    cutOffTimesPdf,
    customerServiceLink,
    note,
  } = toggle ? scheduleForOneWay : scheduleForOtherWay
  return (
    <Wrapper>
      <FlexCard width={{ md: 8 }}>
        <>
          <TopWrapperFlexBox
            itemsCenter
            css={css`
              justify-content: flex-start;
              margin-bottom: 20px;
            `}
          >
            <HeadlineWrapperFlexBox>
              <Headline
                styledAs={'h2'}
                css={css`
                  margin-bottom: 0 !important;
                `}
              >
                {routeName}
              </Headline>
              <SwapHorziontal
                fontSize="1.5em"
                onClick={() => setToggle(!toggle)}
                color={theme.colors.text.secondary.primary}
                css={css`
                  margin-left: 10px;
                  cursor: pointer;
                `}
              />
            </HeadlineWrapperFlexBox>
            <StyledAssetLink {...schedulePdfForDownload} />
          </TopWrapperFlexBox>
          {sailingTime && (
            <div>
              {t('SAILING-TIME')}: {sailingTime}
            </div>
          )}
        </>
        <FlexBox>
          {isContentfulType(
            scheduleTable.__typename,
            'contentful_FreightScheduleTableDaily',
          ) && <FreightScheduleTableDaily {...scheduleTable} />}
          {isContentfulType(
            scheduleTable.__typename,
            'contentful_FreightScheduleTableFrequent',
          ) && <FreightScheduleFrequent {...scheduleTable} />}
          {isContentfulType(
            scheduleTable.__typename,
            'contentful_FreightScheduleTableSeasonal',
          ) && <FreightScheduleSeasonal {...scheduleTable} />}
        </FlexBox>
        {note && <CardTextBlock text={note} />}
      </FlexCard>
      <FlexCard width={{ md: 4 }}>
        <FlexBox directionColumn itemsBaseline>
          <CardTextBlock
            title={t('CUT-OFF-TIMES')}
            titleTag={'h3'}
            text={rightTextSection}
          />
          {cutOffTimesPdf && (
            <p>
              <AssetLink {...cutOffTimesPdf} />
            </p>
          )}
          {customerServiceLink && (
            <p>
              <EntryLink {...customerServiceLink} />
            </p>
          )}
        </FlexBox>
      </FlexCard>
    </Wrapper>
  )
}

export const FreightShippingScheduleFragment = graphql`
  fragment FreightShippingSchedule on contentful_FreightSchedule {
    schedulePdfForDownload {
      ...AssetLink
    }
    scheduleForOneWay {
      routeName
      sailingTime
      note
      rightTextSection
      cutOffTimesPdf {
        ...AssetLink
      }
      scheduleTable {
        ...Schedules
      }
      customerServiceLink {
        ...ItemLink
      }
    }
    scheduleForOtherWay {
      routeName
      sailingTime
      note
      rightTextSection
      cutOffTimesPdf {
        ...AssetLink
      }
      scheduleTable {
        ...Schedules
      }
      customerServiceLink {
        ...ItemLink
      }
    }
  }

  fragment Schedules on contentful_Entry {
    __typename
    ... on contentful_FreightScheduleTableDaily {
      departuresCollection(limit: 20) {
        items {
          time
          weekday
        }
      }
      arrivalsCollection(limit: 20) {
        items {
          time
          weekday
        }
      }
    }
    ... on contentful_FreightScheduleTableFrequent {
      freightScheduleListCollection(limit: 7) {
        items {
          departureTimes
          weekday
        }
      }
    }
    ... on contentful_FreightScheduleTableSeasonal {
      seasonalScheduleCollection(limit: 7) {
        items {
          departures
          arrivals
          fromDate
          toDate
        }
      }
    }
  }
`

export default FreightShippingSchedule
